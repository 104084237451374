import React, { PureComponent } from 'react'

import getFirebase from '../firebase/firebase'

class MyAccountMenu extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      companies: [],
      roles: [],
      section: props.section,
    }
  }

  componentDidMount() {
    this.searchUserCompanies().then(companies => {
      this.setState({
        companies,
      })
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    })
  }

  searchUserCompanies = () => {
    const { auth, firestore } = getFirebase()
    const userRef = firestore.collection('Users').doc(auth.currentUser.uid)
    return new Promise(resolve => {
      firestore
        .collection('Companies')
        .where('users', 'array-contains', userRef)
        .get()
        .then(companiesDoc => {
          if (companiesDoc.docs.length) {
            resolve(companiesDoc.docs)
          } else {
            resolve([])
          }
        })
    })
  }

  handleSectionChange = (section, params) => {
    const { handleSectionChange } = this.props
    this.setState({
      section,
    })
    handleSectionChange(section, params)
  }

  render() {
    const { companies, roles, section } = this.state
    return (
      <div>
        <ul className="list mao pa0 w-100 flex flex-wrap-ns ph2 f6">
          <li>
            <button
              type="button"
              className={`bg-transparent outline-0 pointer ba black pa2 dib no-underline ${
                section === 'profile' ? 'b--black-10' : 'b--transparent'
              }`}
              onClick={() => this.handleSectionChange('profile')}
            >
              Minha Conta
            </button>
          </li>
          <li>
            <button
              type="button"
              className={`bg-transparent outline-0 pointer ba black pa2 dib no-underline ${
                section === 'memorials' ? 'b--black-10' : 'b--transparent'
              }`}
              onClick={() => this.handleSectionChange('memorials')}
            >
              Meus Memoriais
            </button>
          </li>
          <li>
            <button
              type="button"
              className={`bg-transparent outline-0 pointer ba black pa2 dib no-underline ${
                section === 'tributes' ? 'b--black-10' : 'b--transparent'
              }`}
              onClick={() => this.handleSectionChange('tributes')}
            >
              Meus Tributos
            </button>
          </li>
          {roles.includes('admin') && (
            <li>
              <button
                type="button"
                className="bg-transparent outline-0 pointer bn black pa2 dib no-underline"
                onClick={() => this.handleSectionChange('admin')}
              >
                Admin
              </button>
            </li>
          )}
        </ul>
        {companies.length > 0 && (
          <section className="mt4">
            <h2 className="f4 fw4 pl3">Empresas</h2>
            {companies.map((company, index) => {
              const companyId = company.id
              const { name } = company.data()
              return (
                <div key={companyId} className="pl3">
                  <button
                    type="button"
                    className="bg-transparent bn rl pa0 pointer underline-hover b fw4 f7 lh-title"
                    onClick={() =>
                      this.handleSectionChange('company', { companyId, companyName: name })
                    }
                  >
                    {name}
                  </button>
                </div>
              )
            })}
          </section>
        )}
      </div>
    )
  }
}

export default MyAccountMenu
