import React, { PureComponent } from 'react'

import getFirebase from '../firebase/firebase'

class MyMemorials extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      memorials: [],
    }
  }

  componentDidMount() {
    this.searchData().then(memorials => {
      this.setState({
        memorials,
      })
    })
  }

  searchData = () => {
    const { firestore } = getFirebase()
    const { email } = this.props
    const firestoreSettings = {
      timestampsInSnapshots: true,
    }
    firestore.settings(firestoreSettings)
    const memorials = []
    return new Promise(resolve => {
      this.searchMemorials(firestore, email)
        .then(memorialObjs => {
          const promises = []
          memorialObjs.map((memorialItem, index) => {
            const personId = memorialItem.data().person.id
            return promises.push(
              this.searchPersonData(firestore, personId).then(personData => {
                const memorialInfo = {
                  memorialId: memorialItem.id,
                  available: memorialItem.data().available,
                  personCity: personData.city,
                  personName: personData.personName,
                  personPhoto: memorialItem.data().personPhoto,
                }
                memorials.push(memorialInfo)
              })
            )
          })
          return Promise.all(promises)
        })
        .then(() => {
          resolve(memorials)
        })
    })
  }

  searchPersonData = (firestore, personId) =>
    new Promise(resolve => {
      firestore
        .collection('People')
        .doc(personId)
        .get()
        .then(personDoc => {
          resolve(personDoc.data())
        })
    })

  searchMemorials = (firestore, userEmail) =>
    new Promise(resolve => {
      firestore
        .collection('Memorials')
        .where('userEmail', '==', userEmail)
        .get()
        .then(memorialDoc => {
          resolve(memorialDoc.docs)
        })
    })

  render() {
    const { memorials } = this.state
    return (
      <section className="flex flex-wrap">
        <h2 className="w-100 fw5 ml2 mb4">Meus Memoriais</h2>
        <ul className="flex list ma0 pa0 overflow-x-auto flex-wrap-ns">
          {memorials.map((memorial, index) => {
            const { available } = memorial
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className="ph2">
                <div className="tc w4">
                  <div
                    className="w4 h4 br-pill cover bg-center center"
                    style={{
                      backgroundImage: `url(${memorial.personPhoto})`,
                    }}
                  />
                  <h3 className="f5 h2">{memorial.personName}</h3>
                  <div>
                    {available ? (
                      <div className="br-pill dib center f7 pv1 ph3 bg-blue white mb3">
                        Publicado
                      </div>
                    ) : (
                      <div className="br-pill dib center f7 pv1 ph3 bg-mid-gray black mb3">
                        Rascunho
                      </div>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary btn-primary-outline btn-small"
                    onClick={() => {
                      window.location.href = `/edit-memorial?id=${memorial.memorialId}`
                    }}
                  >
                    Gerenciar
                  </button>
                </div>
              </li>
            )
          })}
          <li className="pl2 pr3">
            <div className="tc w4">
              <button
                type="button"
                className="bn pointer w4 h4 br-pill cover bg-center center bg-mid-gray mb4 flex items-center justify-center fw6 dark-gray hover-bg-dark-gray hover-white bg-animate"
                onClick={() => {
                  window.location.href = '/create-memorial'
                }}
              >
                Criar
                <br />
                Memorial
              </button>
            </div>
          </li>
        </ul>
      </section>
    )
  }
}

export default MyMemorials
