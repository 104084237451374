import React, { Component } from 'react'

import MyTributes from '../Tributes/MyTributes'

class MyCompany extends Component {
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    })
  }

  render() {
    const { companyId, companyName, email } = this.props
    return (
      <div className="w-100 flex">
        <div className="">
          <h2 className="w-100 fw5 ml2 mb4">{companyName}</h2>
          <MyTributes email={email} companyId={companyId} />
        </div>
      </div>
    )
  }
}

export default MyCompany
