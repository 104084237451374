import React, { PureComponent } from 'react'

import { withFirebase } from '../components/firebase/context'
import { AuthUserContext } from '../components/Session'
import MyAccountMenu from '../components/MyAccount/MyAccountMenu'
import MyMemorials from '../components/Memorials/MyMemorials'
import MyProfile from '../components/MyAccount/MyProfile'
import MyCompany from '../components/MyAccount/MyCompany'
import MyTributes from '../components/Tributes/MyTributes'

class MyAccount extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      section: 'profile',
    }
    this.handleSectionChange = this.handleSectionChange.bind(this)
  }

  handleSectionChange = (section, params) => {
    this.setState({ section, ...params })
  }

  render() {
    const { section, companyId, companyName } = this.state
    return (
      <>
        <AuthUserContext.Consumer>
          {authUser =>
            authUser ? (
              <section className="mw8-ns center pa2-ns flex flex-wrap">
                <div className="w-100 w-20-ns tl">
                  <MyAccountMenu
                    roles={authUser.roles}
                    section={section}
                    handleSectionChange={this.handleSectionChange}
                  />
                </div>
                <div className="w-100 w-80-ns">
                  {section === 'profile' && (
                    <MyProfile
                      email={authUser.email}
                      userName={authUser.userName}
                      photoURL={authUser.photoURL}
                      authUser={authUser}
                    />
                  )}
                  {section === 'memorials' && <MyMemorials email={authUser.email} />}
                  {section === 'tributes' && <MyTributes email={authUser.email} />}
                  {section === 'company' && (
                    <div>
                      <MyCompany
                        email={authUser.email}
                        companyName={companyName}
                        companyId={companyId}
                      />
                    </div>
                  )}
                </div>
              </section>
            ) : (
              <div />
            )
          }
        </AuthUserContext.Consumer>
      </>
    )
  }
}

export default withFirebase(MyAccount)
