/* eslint-disable no-debugger */
import React, { PureComponent } from 'react'

import getFirebase from '../firebase/firebase'

const slugify = require('slugify')

class MyTributes extends PureComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      tributes: [],
    }
  }

  componentDidMount() {
    this.searchData().then(tributes => {
      this.setState({
        tributes,
      })
    })
  }

  searchData = () => {
    const { firestore } = getFirebase()
    const { companyId, email } = this.props
    const firestoreSettings = {
      timestampsInSnapshots: true,
    }
    firestore.settings(firestoreSettings)
    const tributes = []
    // const promises;

    async function getCompanyData() {
      return new Promise(resolve => {
        firestore
          .collection('Companies')
          .doc(companyId)
          .get()
          .then(companyDoc => {
            resolve(companyDoc.data())
          })
      })
    }

    async function getPersonData(personRef) {
      return new Promise(resolve => {
        personRef.get().then(personDoc => {
          resolve(personDoc)
        })
      })
    }

    async function getTributeData(personInfo, tributeRef) {
      return new Promise(resolve => {
        tributeRef.get().then(tributeDoc => {
          const returnData = {
            ...personInfo,
            ...tributeDoc.data(),
          }
          resolve(returnData)
        })
      })
    }

    async function getCompanyPeopleData(peopleRefs) {
      const promises = []
      peopleRefs.map(personRef => promises.push(getPersonData(personRef)))
      return Promise.all(promises)
    }

    async function getCompanyPeopleTributesData(tributesRefs) {
      const promises = []
      tributesRefs.map(tributeRef => {
        const personInfo = {
          id: tributeRef.id,
          isAvailable: true,
          personId: tributeRef.personId,
          personName: tributeRef.personName,
        }
        const tributePromise = getTributeData(personInfo, tributeRef.obituaries[0])
        return promises.push(tributePromise)
      })
      return Promise.all(promises)
    }

    function getCompanyPeopleTributes(people) {
      const peopleTributes = []
      // eslint-disable-next-line array-callback-return
      people.map(person => {
        if (person.data().obituaries.length > 0) {
          person.data().obituaries.map(obituary => {
            const obj = {
              id: obituary.id,
              personId: person.id,
              personName: person.data().personName,
              obituaries: person.data().obituaries,
            }
            return peopleTributes.push(obj)
          })
        }
      })
      return peopleTributes
    }

    return new Promise(resolve => {
      if (companyId) {
        ;(async () => {
          const companyPromise = await getCompanyData()
          const peoplePromise = await getCompanyPeopleData(companyPromise.people)
          const peopleTributes = getCompanyPeopleTributes(peoplePromise)
          const tributesPromise = await getCompanyPeopleTributesData(peopleTributes)
          resolve(tributesPromise)
        })()
      } else {
        this.searchTributes(firestore, email)
          .then(tributeObjs => {
            const promises = []
            tributeObjs.map((tributeItem, index) => {
              const tributeData = tributeItem.data()
              tributeData.id = tributeItem.id
              return promises.push(
                this.searchPersonData(firestore, tributeData.person.id).then(personDoc => {
                  const personData = personDoc.data()
                  const tributeInfo = {
                    personId: personDoc.id,
                    personCity: personData.city,
                    personName: personData.personName,
                    personPhoto: personData.personPhoto,
                    isAvailable: personData.available,
                    ...tributeData,
                  }
                  tributes.push(tributeInfo)
                  // console.log(tributes)
                })
              )
            })
            return Promise.all(promises)
          })
          .then(() => {
            resolve(tributes)
          })
      }
    })
  }

  searchPersonData = (firestore, personId) =>
    new Promise(resolve => {
      firestore
        .collection('People')
        .doc(personId)
        .get()
        .then(personDoc => {
          resolve(personDoc)
        })
    })

  getPersonObituary = async (firestore, obituaryRef) =>
    new Promise(resolve => {
      obituaryRef.get().then(obituaryDoc => {
        resolve(obituaryDoc.data())
      })
    })

  getCompanyPerson = async (firestore, personRef) =>
    new Promise(resolve => {
      personRef.get().then(personDoc => {
        resolve(personDoc.data())
      })
    })

  getCompany = async (firestore, companyId) =>
    new Promise(resolve => {
      firestore
        .collection('Companies')
        .doc(companyId)
        .get()
        .then(companyDoc => {
          resolve(companyDoc.data())
        })
    })

  searchTributes = (firestore, userEmail) =>
    new Promise(resolve => {
      firestore
        .collection('Obituaries')
        .where('userEmail', '==', userEmail)
        .get()
        .then(tributeDoc => {
          resolve(tributeDoc.docs)
        })
    })

  render() {
    const { tributes } = this.state
    const { companyId } = this.props
    return (
      <section className="flex flex-wrap">
        <h2 className="w-100 fw5 ml2 mb3">Meus Tributos</h2>
        <ul className="flex flex-wrap list ma0 pa0 pl2 w-100">
          {tributes.map((tribute, index) => {
            const { id, isAvailable, personId, personName } = tribute
            return (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className="w-100 bb b--mid-gray pv2">
                <div className="">
                  <h3 className="mb2 f5 f4-ns fw3 h2 mb2 mt0 flex items-center justify-start">
                    {personName}
                    {isAvailable ? (
                      <small className="br-pill dib f7 pv1 ph3 bg-blue white ml2">Publicado</small>
                    ) : (
                      <small className="br-pill dib f7 pv1 ph3 bg-blue white ml2">Rascunho</small>
                    )}
                  </h3>
                  <div className="flex">
                    <button
                      type="button"
                      className="btn btn-primary btn-primary-outline btn-small db mb2 mr2"
                      onClick={() => {
                        window.location.href = `/edit-tribute?id=${id}`
                      }}
                    >
                      Gerenciar
                    </button>
                    {isAvailable && (
                      <a
                        className="btn btn-primary btn-primary-outline btn-small mr2 db mb2"
                        href={`/tributes/${slugify(personName, { lower: true })}/${personId}`}
                        target="blank"
                      >
                        Visualizar
                      </a>
                    )}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
        <button
          type="button"
          className="btn btn-primary btn-small ml2 mt3"
          onClick={() => {
            if (companyId) {
              window.location.href = `/create-tribute/?companyId=${companyId}`
            } else {
              window.location.href = '/create-tribute'
            }
          }}
        >
          Adicionar Tributo
        </button>
      </section>
    )
  }
}

export default MyTributes
