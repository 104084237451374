import React, { PureComponent } from 'react'

class MyProfile extends PureComponent {

  render() {
    const { authUser, email, photoURL, userName } = this.props
    const isFacebook = authUser.providerData[0].providerId === 'facebook.com'
    return (
      <div className="w-100 flex">
        <div className="">
          <h2 className="w-100 fw5 ml2 mb4">Minha Conta</h2>
          <div className="ml2">
            <div className="flex">
              {photoURL && <img alt={userName} src={photoURL} className="w3 h3 br-pill mr2" />}
              <div className="flex flex-wrap items-center">
                <div>
                  <h3 className="w-100 mt0 mb1">{userName}</h3>
                  <div className="w-100 mb1">{email}</div>
                  {isFacebook && (
                    <div className="br-pill bg-blue ph2 white f7 lh-solid dib">
                      <small className="lh-solid pv1 db">Facebook</small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MyProfile
